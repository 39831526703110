
.errorSpan{
    float: left;
    margin-bottom: 10px;
    color: red;
}

a {
    color: #000;
}

a:hover{
    color: #000;
    background-color: #ccc;
}