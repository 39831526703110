.form_control{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: rgba(236, 207, 207, 0.534);
    background-clip: padding-box;
    border: 1px solid red;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form_control:focus{
    color: #495057;
    background-color: rgba(236, 207, 207, 0.534);
    border-color: #ff0303;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.disabled:disabled{
    cursor: not-allowed !important;
}

.disabled:disabled:focus{
    outline: 0 !important;
    border: none !important;
    box-shadow: none !important;
    animation: animate 0.5s ease-in-out infinite alternate;
}

@keyframes animate {
    0%{
        opacity: 0.5;
    }50%{
        opacity: 1;
    }100%{
        opacity: 0.5;
    }
}