/* make a video player */
.player{
    position: relative;
    height: 450px;
    width: 70%;
    overflow: hidden;
    max-width: 100%;
    margin: 0 auto;
}

.loading{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.2);
    z-index: 2;
    animation: animate 1s linear infinite;
}

@keyframes animate {
    0% {
        opacity: 0;
    } 50% {
        opacity: 1;
    } 100% {
        opacity: 0;
    }
}