.orderCardOnline{
  background-color: rgb(248, 247, 164);
  font-size: 20px;
  border-left: 10px solid green;
  border-radius: 5px;
  margin-bottom:2px;
}
.reject-reason-row {
  margin-bottom: 10px;
}
.accept-reject-row {
  margin-top: 10px;
  margin-bottom: 10px;
}