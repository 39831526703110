.orderDetailsItems{
    background-color: rgb(250, 229, 229);
    height: auto;
    min-height: 100px;
    padding-top: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.orderDetailsLeft{
    float: left;
    background-color:white;
}
.orderDetailsHead{
    background-color: white;
}
.orderDetailsRight{
    border-left-width: 2px;
    border-left-color: black;
    border-left-style: solid ;
}