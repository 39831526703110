.formErrors{
    float: left;
    margin-bottom: 10px;
    color: red;
}
.formContainer{
    margin-bottom: 20px;
}

#AddressLooupButton{
    float: right;
}
.singleCustomerRowInForm{
    border-bottom: 1px solid grey;
    padding: 5px;
}