.navbar {
  background-color: #060b26;
  height: 0px;
  display: flex;
  justify-content: felx-start;
  align-items: center;
  z-index: 9999;
  visibility: collapse;
}
.nav-menu {
  background-color: #060b26;
  width: 50px;
  height: 100vh;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 9999;
}
.nav-menu.active {
  transition: 350ms;
}
.orderCard svg{
  width: 30px;
  height: 30px;
}
.nav-menu svg, .formContainer svg{
  width: 30px;
  height: 30px;
  display: block;
  margin-bottom: 30px;
}
.formContainer{
  padding-bottom: 20px;
}

.nav-item{
  position: relative;
}

.nav-feature{
  position: absolute;
  top: -8px;
  left: 18px;
}