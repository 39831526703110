.form{
    margin-top: 5px;
}
/*
input{
    padding: 5px 5px !important;
    line-height: 0px !important;
}
*/

dialog {
    position:absolute;
    top: 0 !important;
    margin-left:40px !important;
}
.MuiDialog-scrollPaper {
    display: flex;
    margin-left: 8%;
    align-items: flex-start !important;
   justify-content: flex-start !important;  /* Make this 'center' for aligning the box in the middle */
}
.buttonrow{
    display: block;
    margin-top: 10px;
}