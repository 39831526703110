.OrderDetailsRow {
    line-height: 1px;
    min-height: 1px;
    height: 1px;
    padding:0px !important;
 }
 .OrderDetailsHead{
     height: 20px;
 }
 .OrderDetailsheadcolumn{
     width: 60%;
 }
.notesColumn{
    line-height: 25px;
}
.orderDetailsHeader{
    margin-bottom: 5px;
    padding-bottom: 5px;
}
.detailsLine{
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid grey;
}