body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px 0px 0px 0px;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.row{
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.resutaurant{
  position: relative;
    display: inline;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    margin-right: 10px;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.float-left{
  float :left;
  clear:right;
  margin-right: 10px;
  margin-left: 10px;
}

.clear-left{
  clear:left;
  margin-right: 10px;
  margin-left: 10px;
}

.fixed-min-height{
  min-height: 100px;
}