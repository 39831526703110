.orderCard, .bookingCard{
  background-color: white;
  min-height: 50px;
  font-size: 20px;
  padding-top:3px;
  border-radius: 5px;
  margin-bottom:4px;
  cursor: pointer;
}
.orderCardNameLine{
  margin-left: 10px;
}