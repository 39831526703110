.App {
  background: #d7dce6;
  height: 100vh;
}
.main-container{
  margin-left: 50px;
}
.background-white {
  background-color: white;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #e3e5e9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rmdp-input{
  height: 35px !important;
  width: 100% !important;
}

.margin-top-10{
  margin-top: 10px;
}
.margin-top-5{
  margin-top: 5px;
}
.margin-bottom-10{
  margin-bottom: 10px;
}
.margin-bottom-40{
  margin-bottom: 40px;
}
.dialogHeader{
  font-size: 22px;
  padding-top: 5px;
  border-bottom: 1px solid grey;
}
.clickable { 
  background-color: azure;
  cursor: pointer; 
}
.tab-content{
  background-color: white;
  min-height: 200px;
}

.login-screen{
  padding-top: 50px;
  background-color: #d7dce6;
}
.settingsBtn{
    margin-right: 3px;
}
body {
  background: black;
}
.nav-call-item {
  background-color: #004A7F;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
@-webkit-keyframes glowing {
  0% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; -webkit-box-shadow: 0 0 3px #B20000; }
}

@-moz-keyframes glowing {
  0% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; -moz-box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; -moz-box-shadow: 0 0 3px #B20000; }
}

@-o-keyframes glowing {
  0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}

@keyframes glowing {
  0% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
  50% { background-color: #FF0000; box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #B20000; box-shadow: 0 0 3px #B20000; }
}

.sm-group-items-sel{
  border: 1px dotted grey;
  padding: 5px 5px 5px 5px;
  margin-bottom: 10px;
}