.orderDetailsLevel1, .orderDetailsLevel2, .orderDetailsLevel3{
    margin-bottom: 5px;
    padding-bottom: 7px;
}
.orderDetailsLevel3{
    margin-bottom: 15px;
}
button.normal, button.item, button.notesBtn{
    margin: 2px;
    padding: 5px !important;
}
.foodItem{
    background: rgb(150, 10, 33) !important;
    color:#FFFFFF;
    margin: 2px;
    padding: 5px !important;
    text-transform: capitalize;
}
.classic{
    background: rgba(76, 223, 108, 0.699)  !important;
    color:rgb(42, 5, 179);
    padding: 5px !important;
    margin: 2px;
}

.classicItem{
    background: rgba(91, 76, 223, 0.699)  !important;
    padding: 5px !important;
    margin: 2px;
}

.spacedButton{
    padding: 5px !important;
    margin: 2px;
}