.OrdersDetailsItemsContainer{
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0.5px dotted rgb(226, 225, 225);
  border-left: 0px;
  border-style: solid;
}
.OrdersDetailsItemsContainer .btn-group .btn {
  font-size: 18px;
}